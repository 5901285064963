<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-send-mail
        :showing.sync="showingDialogs.sendEmail"
        :asunto="`Parte de trabajo  ${formattedItem.id}`"
        :plantillas="sendEmail.plantillas"
        :destinatarios="sendEmail.destinatarios"
        @click-send="clickSend"
      />
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :descargado="formattedItem.descargado"
            :ffijada="formattedItem.ffijada"
            :avisar-antes-ir="formattedItem.avisar_antes_ir"
            :mano-de-obra-facturable="formattedItem.manodeobrafacturable"
            :idtfacturacion-kilometros="formattedItem.idtfacturacion_kilometros"
            :cant-acciones-realizadas="formattedItem.cant_acciones_realizadas"
            :cant-acciones-total="formattedItem.cant_acciones_realizadas + formattedItem.cant_acciones_pendientes"
            :tmotivo-bloqueo-descripcion="formattedItem.tmotivo_bloqueo_ot_descripcion"
            :proximas-revisiones="proximasRevisiones"
            :con-avisos="formattedItem.count_avisos > 0"
            @click-chip="clickChip"
          />
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="estadoPartePendiente && !offline && formattedItem.asignado_a_mi === 1 && !formattedItem.descargado_por_mi"
        :icon="$vuetify.icons.values.download"
        @click="clickDownload"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ParteTrabajoViewData'
import ExtraSubtitle from './components/ExtraSubtitle'
import { TABLA, PARTE_TRABAJO, OBJETOS } from '@/utils/consts'
import { get, sync } from 'vuex-pathify'
import { getLatLongURL, captureGeolocation } from '@/utils/maps'
import  filters from '@/utils/filters'
import { currentDateTime, toDateTime } from '@/utils/date'
import { downloadParteTrabajo, downloadTablasGlobales } from '@/sync/download'
import { encodeBase64 } from '@/utils/router'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      offline: false,
      moreInfoRows: {
        parte: {
          title: 'Parte',
          rows: [
            'idparte_trabajo',
            { name: 'fparte', filter: this.$options.filters.shortDate },
            'orden_trabajo_serie_numero',
            'tactuacion_descripcion',
            'prioridad_descripcion',
            { name: 'forma_pago_ot_descripcion', label: 'Forma de pago' },
            'almacen_descripcion',
            { name: 'ffinparte', filter: this.$options.filters.shortDateTime },
            { name: 'ffijada', filter: this.$options.filters.shortDate },
            { name: 'fprevista_fin', filter: this.$options.filters.shortDate },
            { name: 'orden_trabajo_fprevista', filter: this.$options.filters.shortDate },
            { name: 'manodeobrafacturable', filter: this.$options.filters.humanizeBoolean },
            'tfacturacion_kilometros_desc',
            { name: 'avisar_antes_ir', filter: this.$options.filters.humanizeBoolean },
            'orden_trabajo_solicitante',
            'tincidencia_ot_descripcion',
            'orden_trabajo_notas_internas',
            'observacion',
            'firmante',
            'dni_firmante',
            { name: 'ffirma', filter: this.$options.filters.shortDate },
          ],
        },
        sistema: {
          title: 'Sistema',
          rows: [
            'sistema_codigo',
            'sistema_nabonado1',
            'sistema_descripcion',
            'tsistema_descripcion',
            'sistema_direccion',
            'cliente_direccion_observacion',
            'zona_descripcion',
            'tsector_descripcion',
            'central_alarma_nombre',
            'comercial_nombre',
            'agente_comercial_nombre',
          ],
        },
        cliente: {
          title: 'Cliente',
          rows: [
            'idcliente',
            'cliente_nombre',
          ],
        },
      },
      toolbarOptions: {
        sistema: {
          title: 'Ir al sistema',
          visible: true,
        },
        irOrden: {
          title: 'Ir a la orden de trabajo',
          visible: true,
        },
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        geolocalizar: {
          title: 'Capturar geolocalización actual',
          visible: true,
          icon: 'mapLocation',
        },
        anular: {
          title: 'Anular',
          visible: true,
          icon: 'delete',
        },
        enviarEmail: {
          title: 'Enviar',
          visible: true,
          icon: 'email',
        },
        addMovimientoCaja: {
          title: 'Añadir movimiento de caja ',
          visible: true,
          icon: 'movimientoCaja',
        },
      },
      showingDialogs: {
        moreInfo: false,
        sendEmail: false,
      },
      proximasRevisiones: [],
      sendEmail: {
        plantillas: [],
        destinatarios: [],
      },
    }
  },
  computed: {
    estadoPartePendiente () {
      return this.item?.dataset?.estado === PARTE_TRABAJO.estados.pendiente
    },
    estadoParteFinalizado () {
      return this.item?.dataset?.estado === PARTE_TRABAJO.estados.finalizado
    },
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idparte_trabajo
        item.title = this.$online.parteTrabajo.title(item)
        item.subtitle = this.$online.parteTrabajo.subtitle(item)
        item.alerts = []
        if (item.count_avisos> 0) {
          item.alerts.push({
            value: 'Hay avisos activos en el cliente o sistema.',
            type: 'warning',
            options: {
              actions: [{ text: 'Ver', flat: true, handler: this.irAClienteAviso }],
              persistent: true,
            },
          })
        }
        if (item.cant_partes_pendientes > 0) {
          item.alerts.push({ value: 'OT con más partes de trabajo pendientes', type: 'warning' })
        }
        if (item.descargado) {
          item.alerts.push({ value: 'Parte descargado por otro técnico. No se puede modificar', type: 'warning' })
        }
        if (this.estadoPartePendiente) {
          item.badge = 'Pendiente'
          item.badgeColor = PARTE_TRABAJO.colores.pendiente
        } else if (this.estadoParteFinalizado) {
          item.badge = 'Finalizado'
          item.badgeColor = PARTE_TRABAJO.colores.finalizado
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    usuarioIdalmacen: get('usuario/idalmacen'),
    usuarioUltimaDescargaGlobales: sync('usuario/ultimaDescargaGlobales'),
    appComerciales: get('usuario/appComerciales'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      const inIdsPartesOffline = await this.$offline.parteTrabajo.inIdsPartesOffline()
      this.offline = inIdsPartesOffline.indexOf(this.routeParams.idparte_trabajo) >= 0
      this.title = `${this.item.dataset.orden_trabajo_serie_numero} > ${this.routeParams.idparte_trabajo}`
      const [datasetProximasRevisiones] = await Data.selectProximasRevisiones(this, this.item.dataset.idsistema)
      this.proximasRevisiones = datasetProximasRevisiones
      const [datasetEmailsSAT] = await this.$online.sistemaTelefono.selectConSAT(
        this.item.dataset.idsistema, this.item.dataset.idcliente
      )
      if (datasetEmailsSAT.length === 0) {
        this.$alert.showSnackbarWarning(
          'Cliente sin dirección de correo electrónico de servicio técnico',
          { persistent: true }
        )
      }
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
          this.toolbarOptions.map.visible = !!this.item.dataset.latitud && !!this.item.dataset.longitud
          this.toolbarOptions.anular.visible = !this.item.dataset.descargado && this.hasViewPerm(this.permissions.partes.anular) && this.estadoPartePendiente
          this.toolbarOptions.enviarEmail.visible = this.estadoParteFinalizado

        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idparte_trabajo,
        this.item.dataset.idorden_trabajo,
        this.item.dataset.idsistema,
        this.usuarioIdtecnico,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante,
      )
      // llamadas batch
      const datasetMaterialInstalar = resp.data.selectLparteTrabajoSubsis.result.dataset
      const datasetMaterialAfectado = resp.data.selectParteTrabajoMatsistSubsis.result.dataset
      const datasetMaterialRetirado = resp.data.parteTrabajoMatsistRetirado.result.dataset
      const datasetSubsis = resp.data.ordenTrabajoSubsis.result.dataset
      const datasetServiciosContratados = resp.data.sistemaCuota.result.dataset
      const datasetRevisiones = resp.data.sistemaMant.result.dataset
      const datasetTecnicosAsignados = resp.data.parteTrabajoTecnico.result.dataset
      const parteAsignado = resp.data.parteTrabajoAsignado.result
      // Habilitar o no el enlace a sistemas si tengo el parte asignado o tengo permisos de visualización
      this.toolbarOptions.sistema.visible = parteAsignado || this.hasViewPerm(this.permissions.sistema.id)
      // material a instalar
      const detailMaterialInstalar = this.addDetail(
        'materialInstalar', 'Material a instalar/facturar', 'Material a instalar y/o servicios a facturar', 'materialInstalar'
      )
      let countMaterialInstalar = 0
      let totalsMaterialInstalar = []
      for (const rowMaterialInstalar of datasetMaterialInstalar) {
        countMaterialInstalar += rowMaterialInstalar.unidades
        totalsMaterialInstalar.push(`${rowMaterialInstalar.subsis_descripcion} (${filters.decimal(rowMaterialInstalar.unidades)})`)
      }
      detailMaterialInstalar.badge = filters.decimal(countMaterialInstalar) || 0
      detailMaterialInstalar.totals = totalsMaterialInstalar.join(', ')
      // material afectado
      const detailMaterialAfectado = this.addDetail(
        'materialAfectado', 'Material afectado', 'Afectado a revisiones o averías', 'materialAfectado'
      )
      let countMaterialAfectado = 0
      let totalsMaterialAfectado = []
      for (const rowMaterialAfectado of datasetMaterialAfectado) {
        countMaterialAfectado += rowMaterialAfectado.material_sistema_unidades
        totalsMaterialAfectado.push(`${rowMaterialAfectado.subsis_descripcion} (${rowMaterialAfectado.material_sistema_unidades.toFixed()})`)
      }
      detailMaterialAfectado.badge = countMaterialAfectado || 0
      detailMaterialAfectado.totals = totalsMaterialAfectado.join(', ')
      // material retirado
      if (datasetMaterialRetirado.length > 0) {
        const detailMaterialRetirado = this.addDetail(
          'materialRetirado', 'Material retirado', 'Material retirado para acciones de taller', 'retiradaTemporal'
        )
        let countMaterialRetirado = 0
        let totalsMaterialRetirado = []
        for (const rowMaterialRetirado of datasetMaterialRetirado) {
          countMaterialRetirado += rowMaterialRetirado.material_sistema_unidades
          totalsMaterialRetirado.push(`${rowMaterialRetirado.subsis_descripcion} (${rowMaterialRetirado.material_sistema_unidades.toFixed()})`)
        }
        detailMaterialRetirado.badge = countMaterialRetirado || 0
        detailMaterialRetirado.totals = totalsMaterialRetirado.join(', ')
      }
      // subsis
      const detailSubsis = this.addDetail(
        'subsis', 'Subsistemas afectados', 'Afectado a instalaciones, revisiones o averías', 'subsistema'
      )
      let countSubsis = 0
      let totalsSubsis = []
      for (const rowSubsis of datasetSubsis) {
        countSubsis += 1
        totalsSubsis.push(rowSubsis.subsis_descripcion)
      }
      detailSubsis.badge = countSubsis || 0
      detailSubsis.totals = totalsSubsis.join(', ')
      // personas de contacto
      if (this.hasViewPerm(this.permissions.sistemaTelefono.id)) {
        const [datasetPersonasContacto] = await Data.selectPersonasContactoCount(
          this, this.item.dataset.idsistema, this.item.dataset.idcliente
        )
        const detailPersonasContacto = this.addDetail(
          'personasContacto', 'Personas de contacto', 'Personas de contacto del cliente/sistema', 'personaContacto'
        )
        detailPersonasContacto.badge = datasetPersonasContacto.length || 0
      }
      // servicios contratados
      const detailServiciosContratados = this.addDetail(
        'serviciosContratados', 'Servicios contratados', 'Servicios contratados y cuotas de mantenimiento', 'servicioContratado'
      )
      let countServiciosContratados = 0
      let totalsServiciosContratados = []
      for (const rowServiciosContratados of datasetServiciosContratados) {
        countServiciosContratados += 1
        totalsServiciosContratados.push(rowServiciosContratados.concepto_cuota_descripcion)
      }
      detailServiciosContratados.badge = countServiciosContratados || 0
      detailServiciosContratados.totals = totalsServiciosContratados.join(', ')
      // revisiones de sistema
      const detailRevisiones = this.addDetail(
        'revisiones', 'Revisiones', 'Revisiones de sistema programadas', 'revision'
      )
      let countRevisiones = 0
      let totalsRevisiones = []
      for (const rowRevision of datasetRevisiones) {
        countRevisiones += rowRevision.cantidad
        totalsRevisiones.push(
          `${rowRevision.tactuacion_descripcion} ${rowRevision.periodo_revision_descripcion} (${rowRevision.cantidad.toFixed()})`
        )
      }
      detailRevisiones.badge = countRevisiones || 0
      detailRevisiones.totals = totalsRevisiones.join(', ')
      // tiempo trabajado
      if (this.hasViewPerm(this.permissions.parteTrabajoTiempoTrabajado.id)) {
        const datasetTiempoTrabajado = await Data.selectTiempoTrabajadoCount(this, this.routeParams.idparte_trabajo)
        const detailTiempoTrabajado = this.addDetail(
          'tiempoTrabajado', 'Tiempo trabajado', 'Registro de tiempo trabajado y desplazamientos', 'tiempoTrabajado'
        )
        const tiempoManoObra = this.$options.filters.humanizeDuration(datasetTiempoTrabajado.tiempoManoObra)
        const tiempoDesplazamiento = this.$options.filters.humanizeDuration(datasetTiempoTrabajado.tiempoDesplazamiento)
        detailTiempoTrabajado.badge = datasetTiempoTrabajado.count || 0
        if ((datasetTiempoTrabajado.count || 0) === 0) {
          // para destacar que falta introducir tiempo
          detailTiempoTrabajado.badgeColor = 'error'
        }
        detailTiempoTrabajado.totals = `Trabajado: ${tiempoManoObra} / desplazamiento: ${tiempoDesplazamiento}`
      }
      // técnicos asignados
      const detailTecnicosAsignados = this.addDetail(
        'tecnicosAsignados', 'Técnicos asignados', '', 'asignado'
      )
      const tecnicosAsignados = _.map(datasetTecnicosAsignados, 'nombre_y_apellido')
      detailTecnicosAsignados.badge = datasetTecnicosAsignados.length
      detailTecnicosAsignados.totals = tecnicosAsignados.join(', ')
      // ficheros
      if (this.hasViewPerm(this.permissions.parteTrabajoAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'ficheros', 'Adjuntos', 'Adjuntos del parte de trabajo', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
      // notas
      if (this.hasViewPerm(this.permissions.nota.id)) {
        const detailNotas = this.addDetail(
          'notas', 'Notas', 'Notas del parte', 'nota'
        )
        detailNotas.badge = resp.data.selectNota.result.dataset[0].count || 0
      }
      // operaciones CRM
      if (this.appComerciales && this.hasViewPerm(this.permissions.operaciones.id)) {
        const detailOperaciones = this.addDetail(
          'operacionCRM', 'Operaciones', 'Operaciones relacionadas con el parte', 'operacionCRM'
        )
        detailOperaciones.badge = resp.data.selectOperacioncrm.result.dataset[0].count || 0
      }
      // Avisos
      const detailOperaciones = this.addDetail(
        'clienteAviso', 'Avisos', 'Avisos activos en el cliente o sistema', 'clienteAviso'
      )
      detailOperaciones.badge = this.item.dataset.count_avisos
    },
    irAClienteAviso () {
      this.$appRouter.push({
        name: 'clienteavisos__cliente-aviso-list',
        params: {
          idcliente: this.item.dataset.idcliente,
          idsistema: this.item.dataset.idsistema,
        },
      })
    },
    clickDetail (data) {
      if (data.detail.name === 'ficheros') {
        this.$appRouter.push({
          name: 'ficheros__partes-fichero-list',
          params: {
            idtabla: TABLA.parte_trabajo.idtabla,
            id: this.routeParams.idparte_trabajo,
          },
        })
      } else if (data.detail.name === 'revisiones') {
        this.$appRouter.push({
          name: 'sistemas__sistema-mant-list',
          params: {
            idsistema: this.item.dataset.idsistema
          }
        })
      } else if (data.detail.name === 'tecnicosAsignados') {
        this.$appRouter.push({
          name: 'ordenes__orden-trabajo-tecnico-list',
          params: {
            idorden_trabajo: this.item.dataset.idorden_trabajo,
          },
          query: {
            _filter: encodeBase64({
              idparteTrabajo: {
                value: this.routeParams.idparte_trabajo,
                options: { fixed: true },
              },
            }),
          },
        })
      } else if (data.detail.name === 'personasContacto') {
        this.$appRouter.push({
          name: 'telefonos__sistemas-cliente-telefono-list',
          params: {
            idsistema: this.item.dataset.idsistema
          }
        })
      } else if (data.detail.name === 'materialAfectado') {
        this.$appRouter.push({
          name: 'partes__parte_trabajo_material_afectado-subsis-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo
          }
        })
      } else if (data.detail.name === 'notas') {
        this.$appRouter.push({
          name: 'partes__parte-nota-list',
          params: {
            id: this.routeParams.idparte_trabajo,
            idtabla: TABLA.parte_trabajo.idtabla,
          },
          query: {
            extra: encodeBase64([
              { idtabla: TABLA.orden_trabajo.idtabla, id: this.item.dataset.idorden_trabajo },
            ]),
          },
        })
      } else if (data.detail.name === 'operacionCRM') {
        this.$appRouter.push({
          name: 'operacionescrm__ordenes-operacioncrm-list',
          params: {
            idorden_trabajo: this.item.dataset.idorden_trabajo,
          },
        })
      } else if (data.detail.name === 'clienteAviso') {
        this.irAClienteAviso()
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.sistema) {
        this.$appRouter.push({
          name: 'sistemas__sistema-view',
          params: {
            idsistema: this.item.dataset.idsistema,
          }
        })
      } else if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.latitud, this.item.dataset.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      } else if (option === this.toolbarOptions.anular) {
        this.anular()
      } else if (option === this.toolbarOptions.enviarEmail) {
        // hago la consulta a las plantillas únicamente si se hace click en enviar email
        [this.sendEmail.plantillas] = await this.$online.plantillaEmail.selectPlantillaEmail(this, OBJETOS.TFQRMDParteMontaje.idobjeto)
        const [datasetPersonasContacto] = await Data.selectPersonasContactoCount(
          this, this.item.dataset.idsistema, this.item.dataset.idcliente
        )
        this.sendEmail.destinatarios = _.map(_.filter(datasetPersonasContacto, { email_notifica_sat: true }), 'email')
        this.showingDialogs.sendEmail = true
      } else if (option === this.toolbarOptions.irOrden) {
        this.$appRouter.push({
          name: 'ordenes__orden-trabajo-view',
          params: {
            idorden_trabajo: this.item.dataset.idorden_trabajo,
          },
        })
      } else if (option === this.toolbarOptions.addMovimientoCaja) {
        this.$appRouter.push({
          name: 'movimientoscaja__movimiento-caja-add',
          query: {
            idproyecto_contable: this.item.dataset.idproyecto_contable,
            idparte_trabajo: this.routeParams.idparte_trabajo
          }
        })
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección del sistema "${this.item.dataset.sistema_direccion}"?`)
        if (res) {
          await Data.geolocalizar(
            this,
            this.item.dataset.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
    async anular () {
      const res = await this.$alert.showConfirm(`¿Deseas anular el parte de trabajo Nº${this.routeParams.idparte_trabajo}?`)
      if (res) {
        await this.$online.parteTrabajo.anular(this.routeParams.idparte_trabajo)
        await this.$dirty.deleted(this.$dirty.ENTITIES.remote.parteTrabajo, this.routeParams.idparte_trabajo)
        this.$alert.showSnackbarSuccess(`Se ha anulado el parte de trabajo Nº${this.routeParams.idparte_trabajo}`)
        this.$appRouter.go(-1)
      }
    },
    async clickSend (formData) {
      this.$online.informecolageneracion.enviarEmail(
        this.formattedItem.id,
        formData.destinatarios,
        formData.asunto,
        formData.body,
        OBJETOS.TFQRMDParteMontaje.idobjeto,
      )
      this.showingDialogs.sendEmail = false
    },
    async clickDownload () {
      const res = await this.$alert.showConfirm(
        `¿Deseas descargar el parte Nº${this.routeParams.idparte_trabajo}"?`
      )
      if (res) {
        // hour * minute * second * milisecond
        const ayer = Date.now() - (24 * 60 * 60 * 1000)
        if (!this.usuarioUltimaDescargaGlobales || toDateTime(this.usuarioUltimaDescargaGlobales) < ayer) {
          await downloadTablasGlobales(this)
          this.usuarioUltimaDescargaGlobales = currentDateTime()
        }
        await this.$dirty.modified(this.$dirty.ENTITIES.remote.parteTrabajo, this.routeParams.idparte_trabajo)
        await downloadParteTrabajo(this, this.routeParams.idparte_trabajo, this.usuarioIdalmacen, this.usuarioIdtecnico)
        this.$appRouter.replace({ name: 'partes__parte-asignados-list' })
        this.$appRouter.push({
          name: 'offline__parte-trabajo-view',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
        })
      }
    },
    clickChip (name) {
      if (name === 'clienteAviso') {
        this.irAClienteAviso()
      }
    }
  },
}
</script>
